'use strict';


export default class ContentHandler{
  titleCase(str){
    let newstr = (str+'').split(' ');
    for(let i=0;i<newstr.length;i++){
      let copy = newstr[i].substring(1).toLowerCase();
      newstr[i] = newstr[i][0].toUpperCase() + copy;
    }
    newstr = newstr.join(' ');
    return newstr;
  }
  upperCase(str){
    return (str+'').toUpperCase();
  }
  sanitizePhoneNumber(str){
    return (str+'').replace( /[^\d]/g, '' );
  }
  spliceSlice(str, index, count, add) {
    return str.slice(0, index) + (add || '') + str.slice(index + count);
  }
  getTrackingPhoneNumber(props, contractorId = 0){
    if(props.phoneTrackingInfo){
      if(props.phoneTrackingInfo[contractorId]){
        const phoneTrackingInfo = props.phoneTrackingInfo[contractorId];
        if(phoneTrackingInfo){
          return phoneTrackingInfo.inboundNumber;
        }
      }
      if(props.phoneTrackingInfo.fallback){
        return props.phoneTrackingInfo.fallback.inboundNumber;
      }
    }
    return null;
  }
  formatPhoneNumber(str){
    if(str){
      let cleanStr =  str.replace( /[^\d]/g, '' );
      let phoneNumberPartsRegex = /(\d{1})?(\d{3})(\d{3})(\d{4})/;
      let regexMatches = phoneNumberPartsRegex.exec(cleanStr).slice(1);
      let phoneNumnberParts = regexMatches.filter((element)=>{
        return (element);
      });
      let formattedPhoneNumber = '';
      for (let i = 0; i < 2; i++) {
      //while(phoneNumnberParts.length > 1){
        let localSeperator = '';
        if(formattedPhoneNumber){
          localSeperator = '-';
        }
        formattedPhoneNumber = phoneNumnberParts.pop()+localSeperator+formattedPhoneNumber;
      }
      //better way to handle this?
      formattedPhoneNumber = '('+phoneNumnberParts.pop()+') '+formattedPhoneNumber;
      return formattedPhoneNumber;
    }
    return null;
  }
  getOuterHeight(element){
    let style = window.getComputedStyle(element);
    let height = ['height', 'padding-top', 'padding-bottom', 'margin-top', 'margin-bottom']
      .map((key) => parseInt(style.getPropertyValue(key), 10))
      .reduce((prev, cur) => prev + cur);
    return height;
  }
}