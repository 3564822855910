'use strict';

import MissingDataException from './missingDataException.js';

const defaultProperties = {
  rheem: {
    state: '',

    city: '',

    header_air_swoosh_image_url: 'assets/images/air_swoosh.png',
    logo_image_url: 'assets/images/rheem/rheem_header_logo.png',
    product_bubbles_image_url_class: 'rheem-product-bubble',
    main_image_url_class: 'rheem-main-background',
    smile_divider_class: 'smile-divider',
    pro_partner_logo_class: 'pro-partener-logo',
    main_air_swoosh_image_url_class: 'rheem-main-air-swoosh',
    pro_partner_logo_url: 'assets/images/rheem/rheem_pro_partner_logo.png',
    scroller_image_url: 'assets/images/scroller.png',
    headerTitleTemplate: 'COOL COMFORT',

    product_image_url: 'assets/images/rheem/rheem-product-5.png',
    productName: 'Rheem<sup>&reg;</sup> <span class="rheem-styled-product-name">Prestige</span><sup>&reg;</sup> Heat Pump with our High Efficiency Air Handler',
    mainContentTitleTemplate: 'COOL COMFORT,',
    mainContentSubtitleTemplate: '{{upperCase brand}} INNOVATION',
    mainContentBlurb: 'Our highest-efficiency Rheem<sup>&reg;</sup> <span class="rheem-styled-product-name">Prestige</span><sup>&reg;</sup> Series Heating and Cool solutions provide maximum savings and more presice home comfort.',
    bullets: {
      '1': 'Up to 54% More Efficient Cooling<sup>1</sup>',
      '2': 'Up to 21% more efficient heating<sup>2</sup>',
      '3': 'Smart Home Compatabilty',
      '4': 'Promotional Financing Available<sup>3</sup>'
    },
    badges: {
      '1': 'ecoNet',
      '2': 'eStar'
    },
  },
  ruud: {
    state: '',

    city: '',

    header_air_swoosh_image_url: 'assets/images/air_swoosh.png',
    logo_image_url: 'assets/images/ruud/ruud_header_logo.png',
    header_background_class: 'ruud-header-background',
    hide_element: 'hide_element',
    smile_divider_class: 'smile-divider-ruud',
    pro_partner_logo_class: 'pro-partener-ruud-logo',
    ruud_main_content_class : 'ruud-texture',
    product_bubbles_image_url_class: 'ruud-product-bubble',
    main_image_url_class: 'ruud-left-background',
    main_air_swoosh_image_url_class: 'ruud-main-air-swoosh',
    pro_partner_logo_url: 'assets/images/ruud/ruud_pro_partner_logo.png',
    scroller_image_url: 'assets/images/scroller.png',
    headerTitleTemplate: '<span class="ruud-header-title-emphasis">RELIABLE HEATING AND COOLING</span>',

    product_image_url: 'assets/images/product.png',
    productName: 'Ruud<sup>&reg;</sup> <span class="ruud-styled-product-name">Ultra</span><sup>&trade;</sup> Series 90%+ Gas Furnace, AC, Cased Coil',
    mainContentTitleTemplate: 'BEAT THE HEAT {{upperCase city}}',
    mainContentSubtitleTemplate: '',
    mainContentBlurb: 'Experience the Power of a Pro Partner Contractor! Pro Partner Contractors offer the Ruud<sup>&reg;</sup> Quality Pledge and greater peace-of-mind when you purchase Ruud products. When you need an independent heating & cooling contractor you can trust, turn to the pros — Ruud<sup>&reg;</sup> Pro Partners<sup>&trade;</sup>.',
    bullets: {
      '1': 'Up to 54% More Efficient Cooling<sup>1</sup>',
      '2': 'Up to 21% more efficient heating<sup>2</sup>',
      '3': 'Smart Home Compatabilty',
      '4': 'Promotional Financing Available<sup>3</sup>'
    },
    badges: {
      '1': 'ecoNet',
      '2': 'eStar'
    },
  }
};

export default class LandingPageDataHandler {
  constructor(data, options){
    this.defaultProperties = defaultProperties;
    this.options = options;
    this.data = data;
  }
  get(propName){
    return this.data[propName];
  }
  getMergedData(){
    let info = this.getDefaultProperties();
    try{
      let dynamicData = this.get('dynamicData');
      if(!dynamicData){
        throw new MissingDataException('dynamicData');
      }
      if(!this.options.expansion){
        throw new MissingDataException('expansion');
      }
      let expansions = this.findNodeByName(dynamicData, 'expansions');
      let expansionData = this.findNodeByName(expansions.children, this.options.expansion);
      let productInfo = this.findNodeByName(dynamicData, this.findNodeByName(expansionData.children, 'product_info').varValue);
      let brandInfo = this.findNodeByName(dynamicData, this.findNodeByName(productInfo.children, 'brand_info').varValue);
      let cityInfo = this.findNodeByName(dynamicData, this.findNodeByName(expansionData.children, 'city_info').varValue);
      let stateInfo = this.findNodeByName(dynamicData, this.findNodeByName(cityInfo.children, 'state_info').varValue);
      info =  Object.assign({brand: this.options.brand},
        this.flattenNodes(brandInfo.children),
        this.flattenNodes(productInfo.children),
        this.flattenNodes(stateInfo.children),
        this.flattenNodes(cityInfo.children)
      );
    } catch (e) {
      console.log(e.message);
      info =  this.getDefaultProperties();
    }
    return info;
  }
  findNodeByName(data, name){
    let node = data.find((node)=>{
      return (node.varName == name);
    });
    if(!node){
      throw new MissingDataException(name);
    }
    return node;
  }
  flattenNodes(nodes){
    let flattenedNodes = {};
    nodes.forEach((node)=>{
      if(node.types[node.varType].toLowerCase() === 'text'){
        flattenedNodes[node.varName] = node.varValue;
      }else{
        flattenedNodes[node.varName] = this.flattenNodes(node.children);
      }
    });
    return flattenedNodes;
  }
  getDefaultProperties(){
    const defaults = this.defaultProperties[this.options.brand];
    defaults.brand = this.options.brand;
    return defaults;
  }
};