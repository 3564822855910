import { h, Component } from 'preact';

import scrollToElement from 'scroll-to-element';
import parseAddress from 'parse-address-string';

import FontAwesomeIcon from './fontAwesomeIcon.js';
import { faSpinner, faWindowClose } from '@fortawesome/free-solid-svg-icons';

import style from '../../style/form.sass';
import ContentHandler from '../util/contentHandler.js';

const googleAddressComponentTypeFunctions = {
  'room': function(formData, component){
    formData.set('location[unit]', component.long_name);
  },
  'street_address': function(formData, component){
    formData.set('location[street]', component.long_name);
  },
  'street_number': function(formData, component){
    let streetName = formData.has('location[street]')?formData.get('location[street]'):'';
    formData.set('location[street]', component.long_name+' '+streetName);
  },
  'route': function(formData, component){
    let streetNumber = formData.has('location[street]')?formData.get('location[street]'):'';
    formData.set('location[street]', streetNumber+''+component.long_name);
  },
  'locality': function(formData, component){
    formData.set('location[city]', component.long_name);
  },
  'administrative_area_level_1': function(formData, component){
    formData.set('location[state]', component.long_name);
  },
  'country': function(formData, component){
    formData.set('location[country]', component.long_name);
  },
  'postal_code': function(formData, component){
    formData.set('location[zip]', component.long_name);
  },
};

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSendPending: false,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      honeypot: null,
      description: '',
    };

    this.locationInput = null;
    this.locationAutocomplete = null;
    this.onPlaceChanged = this.onPlaceChanged.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancelSubmit = this.cancelSubmit.bind(this);
  }
  componentDidMount(){
    //this.bindLocationInput();
    if(this.props.doShowModal){
      scrollToElement(this.base,{
        align: 'top'
      });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot){
    //this.bindLocationInput();
    if(this.props.doShowModal && this.props.doShowModal != prevProps.doShowModal){
      scrollToElement(this.base,{
        align: 'top'
      });
    }
  }
  bindLocationInput(){
    if(this.props.googleMapsApiKeyIsValid && !this.locationAutocomplete){
      this.locationAutocomplete = new window.google.maps.places.Autocomplete(
        this.locationInput, {
          types: ['geocode'],
        }
      );
      this.locationAutocomplete.addListener('place_changed', this.onPlaceChanged);
    }else{
      this.locationInput.onChange = this.handleInputChange;
    }
  }
  onPlaceChanged(){
    const location = this.locationAutocomplete.getPlace();
    if(location){
      this.setState({location: location});
    }
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let stateUpdate = {
      [name]: value
    };
    this.setState(stateUpdate);
  }
  handleSubmit(event){
    event.preventDefault();
    const formData = new FormData();
    this.addStateInfoToFormData(formData).then((formData)=>{
      return this.addExtraInfoToFormData(formData);
    }).then((formData)=>{
      this.setState({formSendPending: true}, ()=>{
        this.props.sendFormData(formData);
      });
    });
  }
  addStateInfoToFormData(formData){
    return new Promise((resolve)=>{
      formData.set('firstName', this.state.firstName);
      formData.set('lastName', this.state.lastName);
      formData.set('phoneNumber', this.state.phoneNumber);
      formData.set('email', this.state.email);
      formData.set('description', this.state.description);
      resolve(formData);
    });
    //return this.addAddressToFormData(formData);
  }
  addExtraInfoToFormData(formData){
    return new Promise((resolve)=>{
      const contentHandler = new ContentHandler();
      let forwardingPhoneNumber = contentHandler.getTrackingPhoneNumber(this.props);
      formData.set('source', 'metrics');
      formData.set('sourceUrl', window.location.href);
      formData.set('vsref', contentHandler.sanitizePhoneNumber(forwardingPhoneNumber));
      formData.set('contractorId', this.props.modalData.contractor.externaId);
      formData.set('contractorEmail', this.props.modalData.contractor.externalData.email);
      formData.set('contractorName', this.props.modalData.contractor.name);
      resolve(formData);
    });
  }
  addAddressToFormData(formData){
    return new Promise((resolve)=>{
      if(!this.state.location){
        parseAddress(this.locationInput.value, function(err,addressObj){
          formData.set('location[street]', addressObj.street_address1);
          formData.set('location[city]', addressObj.city);
          formData.set('location[state]', addressObj.state);
          formData.set('location[country]', addressObj.country);
          formData.set('location[zip]', addressObj.postal_code);
          resolve(formData);
        });
      }else{
        this.state.location.address_components.forEach((element)=>{
          element.types.forEach((type)=>{
            if(typeof googleAddressComponentTypeFunctions[type] === 'function'){
              googleAddressComponentTypeFunctions[type](formData, element);
            }
          });
        });
        if(!Array.from(formData.keys()).some((key)=>{ return (key.indexOf('location') > -1);})){
          formData.append('location[]', 'N/A');
        }
        resolve(formData);
      }
    });
  }
  cancelSubmit(event){
    event.preventDefault();
    this.props.hideModal();
  }
  render(props, state){
    const spinner = this.state.formSendPending?<FontAwesomeIcon icon={faSpinner} pulse />:null;
    return (
      <form className={style.form}  onSubmit={this.handleSubmit}>
        <div className={style.formLeftContainer}></div>
        <div className={style.formRightContainer}>
          <div className={style.cancelLinkContainer}>
            <a className={style.cancelLink} onClick={this.cancelSubmit} tabIndex="0">
              <FontAwesomeIcon icon={faWindowClose} />
            </a>
          </div>
          <div className={style.formContentContainer}>
            <h2 className={style.formHeader}>
              SCHEDULE SERVICE
            </h2>
            <div className={style.formHeaderSubTitle}>
              Fill out the form below and {props.modalData.contractor.name} will reach out to schedule your service appointment.
            </div>
            <input className={style.input}
              name="firstName"
              type="text"
              placeholder="First Name"
              required
              onChange={this.handleInputChange}
              value={this.state.firstName}
            />
            <input className={style.input}
              name="lastName"
              type="text"
              placeholder="Last Name"
              required
              onChange={this.handleInputChange}
              value={this.state.lastName}
            />
            <input className={style.input}
              name="phoneNumber"
              type="tel"
              placeholder="Phone Number"
              required
              onChange={this.handleInputChange}
              value={this.state.phoneNumber}
            />
            <input className={style.input}
              name="honeypot"
              type="email"
              placeholder="Email"
              required
              onChange={this.handleInputChange}
              value={this.state.honeypot}
            />
            <textarea
              className={style.textarea}
              name="description"
              placeholder="Service Needed"
              onChange={this.handleInputChange}
              value={this.state.description}
            />
            <div className={style.submitButtonContainer}>
              <button className={style.submitButton} disabled={this.state.formSendPending} type="submit">
                SUBMIT
                {spinner}
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}