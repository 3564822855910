import { h, Component } from 'preact';

import style from '../../style/footer.sass';

export default class Footer extends Component {
  render(props, state) {
    return (
      <div id="footer" className={style.container}>
        <div className={style.footerLogo}>
          <img className={style.logo} src="assets/images/ruud/ruud-pro-partner-logo.png" />
        </div>
        <div className={style.footerText}>
          <ul className={style.disclaimers}>
            {props.offer.disclaimers.map(disclaimer=>{
              return (<li>{disclaimer}</li>);
            })}
          </ul>
          <div className={style.financingInfo}>
            <a href="https://www.ruud.com/consumer-programs/kwikcomfort/">Ruud Consumer Financing Page</a>
            <br />
            <a href="https://etail.mysynchrony.com/cs/groups/public/documents/et_appdoc/e026518.html"> Terms and conditions of online credit application</a>
          </div>
        </div>
      </div>
    );
  }
}