import { h, Component } from 'preact';

import style from '../../style/loading.sass';

export default class Loading extends Component {
  render(props) {
    return (
      <div className={style.container}>
        <div className={style.primaryLoadingPanel}>
          <span class={style.loadingBulletOne}>⬤</span>
          <span class={style.loadingBulletSpace}>&nbsp;</span>
          <span class={style.loadingBulletTwo}>⬤</span>
          <span class={style.loadingBulletSpace}>&nbsp;</span>
          <span class={style.loadingBulletThree}>⬤</span>
        </div>
        <div className={style.message}>
          LOADING
        </div>
      </div>
    );
  }
}