import { h, render } from 'preact';

let root;
let env = 'prod';

function init() {
  const App = require('./components/app').default;
  root = render(<App env={env} />, document.body, root);
}


// in development, set up HMR:
if (module.hot) {
  env = 'dev';
  //require('preact/devtools');   // turn this on if you want to enable React DevTools!
  module.hot.accept('./components/app', () => requestAnimationFrame(init) );
}

if (window.app_env) {
  env = window.app_env;
}

init();