import { h, Component } from 'preact';
import scrollToElement from 'scroll-to-element';
import Cookies from 'js-cookie';
import Uuid from 'uuid-js';

import Header from './header.js';
import Divider from './divider.js';
import Footer from './footer.js';
import Loading from './loading.js';
import BrandHero from './brandHero.js';
import MobileCallToAction from './mobileCallToAction.js';
import Contractor from './contractor.js';
import ContractorList from './contractorList.js';
import Product from './product.js';
import Form from './form.js';
import LocationSearch from './locationSearch.js';
import FormResponse from './formResponse.js';
import Modal from './modal.js';

import style from '../../style/app.sass';

import LandingPageDataHandler from '../util/landingPageDataHandler.js';

const METRICS_LANDING_PAGE_DATA_ID_DEV = 2;
const METRICS_LANDING_PAGE_DATA_ID_STAG = 2;
const METRICS_LANDING_PAGE_DATA_ID_PROD = 3;
const METRICS_ACCOUNT_ID_DEV = 38;
const METRICS_ACCOUNT_ID_STAG = 14;
const METRICS_ACCOUNT_ID_PROD = 765;
const METRICS_API_KEY_DEV = '9b568155-8fde-4f53-89f7-01b216f814c8';
const METRICS_API_KEY_STAG = '3637915a-0924-4138-966c-78bf08868a23';
const METRICS_API_KEY_PROD = '852859e5-2c13-41e1-bf60-4ead3fee267e';
const METRICS_BASE_URL_DEV =  'https://'+window.location.hostname.toLowerCase()+':8443/';
const METRICS_BASE_URL_STAG = 'https://vsm-staging01.vitalstorm.com/';
const METRICS_BASE_URL_PROD = 'https://metrics.vitalstorm.com/';
const METRICS_API_URL_ENDPOINT = 'api';
const METRICS_FORM_URL_ENDPOINT = 'booking_form_submission/3b452ef7-dc80-4629-8acc-9cc0267e34b7/';
const METRICS_MISSING_REWRITE_ALERT_URL_ENDPOINT = 'email_form_submission/3b452ef7-dc80-4629-8acc-9cc0267e34b7/';

const GOOGLE_MAPS_API_KEY = 'AIzaSyCYBFCo-neWMM7egCE_yMcG9St1MS0Lt0Y';
//const GOOGLE_MAPS_API_KEY = 'AIzaSyCJBQ9wgAlucnK-75kds5E08Ss08QmN_GA'; //real one hopeully, need to link billing

const CONSTS = {
  'dev': {
    'METRICS_ACCOUNT_ID': METRICS_ACCOUNT_ID_STAG,
    'METRICS_API_KEY': METRICS_API_KEY_DEV,
    'METRICS_BASE_URL': METRICS_BASE_URL_DEV,
  },
  'stag': {
    'METRICS_ACCOUNT_ID': METRICS_ACCOUNT_ID_STAG,
    'METRICS_API_KEY': METRICS_API_KEY_STAG,
    'METRICS_BASE_URL': METRICS_BASE_URL_STAG,
  },
  'prod': {
    'METRICS_LANDING_PAGE_DATA_ID': METRICS_LANDING_PAGE_DATA_ID_PROD,
    'METRICS_ACCOUNT_ID': METRICS_ACCOUNT_ID_PROD,
    'METRICS_API_KEY': METRICS_API_KEY_PROD,
    'METRICS_BASE_URL': METRICS_BASE_URL_PROD,
    'METRICS_API_URL_ENDPOINT': METRICS_API_URL_ENDPOINT,
    'METRICS_FORM_URL_ENDPOINT': METRICS_FORM_URL_ENDPOINT,
    'METRICS_MISSING_REWRITE_ALERT_URL_ENDPOINT': METRICS_MISSING_REWRITE_ALERT_URL_ENDPOINT,
    'GOOGLE_MAPS_API_KEY': GOOGLE_MAPS_API_KEY
  }
};


const offers = {
  oneFinancing: {
    key: 'oneFinancing',
    headline: '0% Financing',
    text: 'for up to 36 Months',
    disclaimers: [
      'With approved credit on qualifying systems',
      'Refer to participating dealer for details',
      'Activation fees and conditions may apply. Ask your contractor for details.'
    ]
  },
  twoService: {
    key: 'twoService',
    headline: '$59 Service Call',
    text: 'with any Repair',
    disclaimers: [
      'Must mention ad at time of booking',
      'Service call fee reduced to $59 with paid repair'
    ]
  },
  threeRebates: {
    key: 'threeRebates',
    headline: 'Up to $1,500 off',
    text: 'a new high efficiency system with utility rebates',
    disclaimers: [
      'Installation must take place within CenterPoint or Entergy service areas',
      'Call for details'
    ]
  },
  fourEstimate: {
    key: 'fourEstimate',
    headline: 'Free Estimates',
    text: 'on System Replacement',
    disclaimers: [
      'With mention of this ad'
    ]
  }
};

export default class App extends Component {
  constructor(props) {
    super(props);

    const uuid = Uuid.create();

    this.consts = Object.assign({}, CONSTS.prod, CONSTS[props.env]);

    this.state = {
      rheemApiAccessToken: null,
      googleMapsApiKeyIsValid: true,
      searchLocation: null,
      offer: this.getOffer(),
      brand: this.getBrand(),
      vsrefdom: this.getVsrefdom(),
      expansion: this.getExpansion(),
      campaignType: this.getCampaignType(),
      source: this.getAdSource(),
      clickId: this.getClickId(),
      device: this.getPlatformType(),
      visitUuid: uuid,
      baseUrl: this.getBaseUrl(props.env),
      contractors: null,
      mergedLandingPageData: null,
      trackingNumber: null,
      infoFetched: false,
      formSendPending: false,
      doShowModal: false,
      modalOverlayHeight: 0,
      modalComponentType: null,
      modalData: null,
      dividerRef: null,
    };

    this.sendClickData();

    //this.setTitleFromBrand();
    //this.setFavIconFromBrand();

    this.listBaseElement = null;
    this.showForm = this.showForm.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.setSearchLocation = this.setSearchLocation.bind(this);
    this.updateModalOverlayHeight = this.updateModalOverlayHeight.bind(this);
    this.sendFormData = this.sendFormData.bind(this);
    this.sendHttpRequest = this.sendHttpRequest.bind(this);
    this.sendClickToCallData = this.sendClickToCallData.bind(this);
    this.onContractorListLinkClick = this.onContractorListLinkClick.bind(this);
  }
  componentDidMount(){
    this.fetchDataFromMetrics();
    window.addEventListener('resize', this.updateModalOverlayHeight);
  }
  componentWillUnmount(){
    window.removeEventListener('resize', this.updateModalOverlayHeight);
  }
  getConst(name){
    return this.consts[name];
  }
  getBaseUrl(){
    return this.getConst('METRICS_BASE_URL');
  }
  constructQueryString(params){
    const esc = encodeURIComponent;
    return Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');
  }
  getBaseFetchOptions(){
    let headers = new Headers({
      'Content-Type' : 'application/json',
      'Accept': 'application/json',
      'X-ApiKey': this.getConst('METRICS_API_KEY')
    });
    return {
      headers:  headers,
      credentials: 'omit',
      method: 'GET',
    };
  }
  getErrorMessageFromResponse(response){
    return new Promise((resolve, reject)=>{
      let errorMsg = 'An Error Occured!';
      if(response.statusText){
        errorMsg += '  '+response.statusText;
      }
      response.json().then((json)=>{
        if(json.error && json.error.exception['0']){
          errorMsg = json.error.exception['0'].message;
        }
        resolve(errorMsg);
      }).catch(()=>{
        resolve(errorMsg);
      });
    });
  }
  getPlatformType(){
    if(navigator.userAgent.match(/mobile/i)) {
      return 'Mobile';
    } else if (navigator.userAgent.match(/iPad|Android|Touch/i)) {
      return 'Tablet';
    } else {
      return 'Desktop';
    }
  }
  sendHttpRequest(options){
    return new Promise((resolve, reject)=>{
      const defaults = this.getBaseFetchOptions();
      options = {...defaults, ...options};
      fetch(options.url, options).then((response)=>{
        if(response.ok){
          response.json().then((responseData)=>{
            resolve(responseData);
          });
        }else{
          this.getErrorMessageFromResponse(response).then((errorMsg)=>{reject(errorMsg)});
        }
      });
    });
  }
  sendFormData(formData){
    return new Promise((resolve, reject)=>{
      fetch(this.state.baseUrl+this.getConst('METRICS_FORM_URL_ENDPOINT'), {method: 'POST', body: formData}).then((response)=>{
        if(response.ok){
          response.text().then((html)=>{
            this.showModal(FormResponse,{
              html: html
            });
          });
        }else{
          this.showModal(FormResponse,{
            html: 'We\'re Sorry, the service encountered an error while processing your request. Please try again and contact the server admin if the error persists.'
          });
        }
      });
    });
  }
  getQueryStringParameterByName(name){
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has(name)){
      return urlParams.get(name);
    }
    return false;
  }
  getBrand(){
    return 'ruud';
  }
  getCampaignType(){
    let expansion = this.getExpansion();
    if(expansion && expansion.indexOf('repair') > -1){
      return 'repair';
    }
    return 'brand';
  }
  getVsrefdom(){
    let vsrefdom = this.getQueryStringParameterByName('_vsrefdom');
    if(!vsrefdom){
      vsrefdom = this.getBrand();
    }
    return vsrefdom;
  }
  getExpansion(){
    return this.getQueryStringParameterByName('expansion');
  }
  getOffer(){
    let offerName = this.getQueryStringParameterByName('offer');
    if(!offerName){
      offerName = 'oneFinancing';
    }
    const offer = offers[offerName];
    if(offer){
      return offer;
    }
    return offers.oneFinancing;
  }
  getAdSource(){
    let source = null;
    let gclid = this.getQueryStringParameterByName('gclid');
    if(gclid){
      source = 'adwords';
    }else if(Cookies.get('gclid')){
      source = 'adwords';
    }
    return source;
  }
  getClickId(){
    let clickId = null;
    let gclid = this.getQueryStringParameterByName('gclid')
    if(gclid){
      clickId = gclid;
      Cookies.set('gclid', clickId, { expires: 14 });
    }else if(Cookies.get('gclid')){
      clickId = Cookies.get('gclid');
    }
    return clickId;
  }
  getMetricsApiKey(){
    return this.getConst('METRICS_API_KEY');
  }
  getMetricsAccountId(){
    return this.getConst('METRICS_ACCOUNT_ID');
  }
  getMissingRewriteAlertUrl(){
    return this.getConst('METRICS_MISSING_REWRITE_ALERT_URL');
  }
  sendClickData(){
    if(this.state.clickId && this.state.source){
      const baseClickData = {
        source: this.state.source,
        device: this.state.device,
        clickId: this.state.clickId,
        visitUuid: this.state.visitUuid.toString()
      };
      this.sendHttpRequest({
        url: this.state.baseUrl+'conversion_tracking/campaign_ad_click/',
        method: 'POST',
        body: JSON.stringify(baseClickData)
      });
    }
  }
  sendClickToCallData(event){
    if(this.state.clickId && this.state.source){
      const calledNumber = event.target.href.replace(/\D/g,'');
      const baseClickData = {
        source: this.state.source,
        device: this.state.device,
        clickId: this.state.clickId,
        visitUuid: this.state.visitUuid.toString(),
        calledNumber: calledNumber
      };
      this.sendHttpRequest({
        url: this.state.baseUrl+'conversion_tracking/click_to_call_event/',
        method: 'POST',
        body: JSON.stringify(baseClickData)
      });
    }
  }
  googleMapsKeyInvalid(){
    this.setState({
      googleMapsApiKeyIsValid: false
    });
  }
  getGoogleMapsApi(){
    window.gm_authFailure = ()=>{
      this.googleMapsKeyInvalid();
    };
    return new Promise((resolve, reject)=>{
      if(window.google && window.google.maps){
        resolve();
      }else{
        const gmScript = document.createElement('script');
        gmScript.src = '//maps.googleapis.com/maps/api/js?key='+this.getConst('GOOGLE_MAPS_API_KEY')+'&libraries=places';
        gmScript.onload = resolve;
        window.document.body.appendChild(gmScript);
      }
    });
  }
  /*
    old code from last version of page. Since we don't use review anymore, no need to port.
    fetchReviewStatistics(contractorCollection){
      let passkey = (this.get('brand').toLowerCase()==='rheem')?RHEEM_BAZAARVOICE_PASSKEY:RUUD_BAZAARVOICE_PASSKEY;
      if(this.get('contractorCount') > 0){
        return Backbone.$.ajax('//api.bazaarvoice.com/data/statistics.json',
          {
          accepts: {
            json: 'application/json'
          },
          data: {
            apiversion: '5.4',
            passkey: passkey,
            filter: 'productid:'+_.map(contractorCollection.pluck('OrganizationID'), (id)=>{
                return encodeURIComponent(id);
              }).join(','),
            stats: 'reviews'
          },
          dataType: 'json'
        }).done((response)=>{
          _.each(response.Results, (result)=>{
            contractorCollection.findWhere({OrganizationID: result.ProductStatistics.ProductId})
              .set('TotalReviewCount', result.ProductStatistics.ReviewStatistics.TotalReviewCount);
          });
          _.invoke(contractorCollection.where({TotalReviewCount: null}), 'set', 'TotalReviewCount', 0);
        });
      }
    }

  */
  setTitleFromBrand(){
    let title = 'Rheem - Cool Comfort';
    if(this.getBrand() === 'ruud'){
      title = 'Ruud - Reliable heating & cooling';
    }
    this.setTitle(title);
  }
  setFavIconFromBrand(){
    let iconPath = './assets/rheem-fav-logo.png';
    //let iconType = 'image/x-icon';
    let iconType = 'image/png';
    if(this.getBrand() === 'ruud'){
      iconType = 'image/png';
      iconPath = './assets/ruud-fav-logo.png';
    }
    this.setFavIcon(iconType, iconPath);
  }
  /*
  setTitleFromLandingPageData(){
    let mergedData = this.landingPageData.getMergedData();
    if(mergedData.city){
      let title = 'Rheem - Cool Comfort in '+mergedData.city;
      if(this.getBrand() === 'ruud'){
        title = 'Ruud - Reliable heating & cooling in '+mergedData.city;
      }
      this.setTitle(title);
    }
  }
  */
  sendRemarketingConversionFromLandingPageData(mergedData){
    if(mergedData.remarketing_code){
      window.google_trackConversion({
        google_conversion_id: parseInt(mergedData.remarketing_code),
        google_remarketing_only: true,
        google_custom_params: window.google_tag_params
      });
    }
  }
  setTitle(title){
    document.title = title;
  }
  setFavIcon(iconType, iconPath){
    const currentIconLink = window.document.head.querySelector('link[rel=icon]');
    if(currentIconLink){
      currentIconLink.remove();
    }
    const newIconLink = document.createElement('link');
    newIconLink.rel = 'icon';
    newIconLink.type = iconType;
    newIconLink.href= iconPath;
    window.document.head.append(newIconLink);
  }
  fetchDataFromMetrics(){
    return this.ensureGeocoder().then(this.getLocation).then((location)=>{
      let params = {};
      if(this.state.expansion){
        params.expansion = this.state.expansion;
      }
      if(this.state.brand){
        params.brand = this.state.brand;
      }
      if(this.state.offer){
        params.offer = this.state.offer.key;
      }
      if(location){
        params.longitude = location.longitude;
        params.latitude = location.latitude;
      }
      this.sendHttpRequest({
        url: this.state.baseUrl+'api/search_third_party_contractor/?'+this.constructQueryString(params),
        method: 'GET',
      }).then((data)=>{
        const lpdh = new LandingPageDataHandler(data.landingPageData, {
          expansion: data.expansion,
          brand: this.state.brand
        });
        this.setState({
          contractors: data.contractors,
          mergedLandingPageData: lpdh.getMergedData(),
          phoneTrackingInfo: data.phoneTrackingInfo,
          expansion: data.expansion,
          infoFetched: true
        });
        if(!data.contractors || data.contractors.length < 1){
          this.showModal(LocationSearch, {
            setSearchLocation: this.setSearchLocation
          });
        }
      }).catch(()=>{
        this.showModal(LocationSearch, {
          setSearchLocation: this.setSearchLocation
        });
      });
    });
  }
  fetchDataFromSearchLocation(){
    return new Promise((resolve, reject)=>{
      this.setState({formSendPending: true}, ()=>{
        let params = {};
        if(this.state.expansion){
          params.expansion = this.state.expansion;
        }
        if(this.state.brand){
          params.brand = this.state.brand;
        }
        if(this.state.offer){
          params.offer = this.state.offer.key;
        }
        if(this.state.searchLocation){
          params = Object.assign(params, this.state.searchLocation);
        }
        this.sendHttpRequest({
          url: this.state.baseUrl+'api/search_third_party_contractor/?'+this.constructQueryString(params),
          method: 'GET',
        }).then((data)=>{
          const lpdh = new LandingPageDataHandler(data.landingPageData, {
            expansion: data.expansion,
            brand: this.state.brand
          });
          this.setState({
            contractors: data.contractors,
            mergedLandingPageData: lpdh.getMergedData(),
            phoneTrackingInfo: data.phoneTrackingInfo,
            expansion: data.expansion,
            infoFetched: true,
            formSendPending: false
          });
          resolve();
        }).catch(()=>{
          this.showModal(LocationSearch, {
            setSearchLocation: this.setSearchLocation
          });
        });;
      });
    });
  }
  ensureGeocoder(){
    return new Promise((resolve, reject)=>{
      if(!this.geocoder){
        this.getGoogleMapsApi().then(()=>{
          this.geocoder = new window.google.maps.Geocoder();
          resolve();
        });
      }else{
        resolve();
      }
    });
  }
  setSearchLocation(location){
    return new Promise((resolve, reject)=>{
      this.setState({searchLocation: location}, ()=>{
        this.fetchDataFromSearchLocation().then(()=>{
          resolve();
        });
      });
    });
  }
  getLocation(model){
    return new Promise((resolve, reject)=>{
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position)=>{
          //let point = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
          resolve(position.coords);
        },()=>{
          if(this.state.searchLocation){
            resolve(this.state.searchLocation);
          }else{
            console.log('Location not enabled');
            resolve();
          }
        });
      } else if(this.state.searchLocation){
        resolve(this.state.searchLocation);
      }else {
        console.log('Location not supported');
        resolve();
      }
    });
  }
  onContractorListLinkClick(e){
    e.preventDefault();
    if(this.listBaseElement){
      scrollToElement(this.listBaseElement,{
        align: 'top'
      });
    }
  }
  showForm(contractor){
    this.showModal(Form, {contractor});
  }
  hideModal(){
    this.setState({
      doShowModal: false,
      modalOverlayHeight:0,
    });
  }
  showModal(componentType, modalData){
    this.setState({
      doShowModal: true,
      modalOverlayHeight: document.body.scrollHeight,
      modalComponentType: componentType,
      modalData: modalData
    });
  }
  updateModalOverlayHeight(){
    this.setState({
      modalOverlayHeight: document.body.scrollHeight
    });
  }
  getLayoutBackground(props, state){
    const layoutBackgroundStyles = [style.layoutBackground];
    /*
    if(state.infoFetched){
      layoutBackgroundStyles.push(style.layoutBackgroundMobile)
    }else{
      layoutBackgroundStyles.push(style.layoutBackgroundLoading);
    }
    */
    return ([
      (<div className={layoutBackgroundStyles.join(' ')}>
        <div className={style.layoutBackgroundOverlay} />
      </div>),
      (<div className={style.layoutBackgroundOverlayMobileExtenstion} />)
    ]);
    return null;
  }
  getContent(props, state){
    const content = {
      top: null,
      butom: null
    };
    if(state.infoFetched){
      content.top = [
          (<div className={style.layoutBackgroundMobileSpacing}><div>O</div><div>O</div></div>),
          (<BrandHero {...props} {...state} />),
          (<Product {...props}  {...state} />)
      ];
      content.bottom = (
        <ContractorList {...props}  {...state}
          ref={refComp => this.listBaseElement = refComp.base}
          showForm={this.showForm}
          setSearchLocation={this.setSearchLocation}
          sendClickToCallData={this.sendClickToCallData}
        />
      );
    }else{
      content.top = (<Loading brand={state.brand} />);
    }
    return content;
  }
  render(props, state) {
    const fontClass = (state.brand === 'ruud')?style.ruudFont:style.rheemFont;
    const ruudLayoutBackground = this.getLayoutBackground(props, state);
    const content = this.getContent(props, state);
    const footerBackgroundStyle = [style.footerBackground];
    if(state.brand === 'ruud'){
      footerBackgroundStyle.push(style.footerBackgroundRuud);
      if(state.campaignType === 'repair'){
        footerBackgroundStyle.push(style.footerBackgroundRuudRepair);
      }
    }else{
      if(state.campaignType === 'repair'){
        footerBackgroundStyle.push(style.footerBackgroundRepair);
      }
    }
    return (
      <div id="app" className={fontClass}>
        <div id="layout" className={style.layout}>
          {ruudLayoutBackground}
          <Header {...props} {...state} />
          {content.top}
          <Divider {...props} {...state} ref={c => !this.state.dividerRef && this.setState({ dividerRef: c })} />
          {content.bottom}
          <div className={footerBackgroundStyle.join(' ')}></div>
          <Footer {...props} {...state} />
        </div>
        <Modal
          {...this.props} {...this.state}
          hideModal={this.hideModal}
          sendFormData={this.sendFormData}
        />
      </div>
    );
  }
}

/*
 <MobileCallToAction {...props} {...state}
  showForm={this.showForm}
  sendClickToCallData={this.sendClickToCallData}
  onContractorListLinkClick={this.onContractorListLinkClick}
/>
*/