import { h, Component } from 'preact';

import style from '../../style/formResponse.sass';

export default class FormResponse extends Component {
  render(props) {
    return (
      <div className={style.formResponse}>
        <div className={style.formResponseLeftContainer}></div>
        <div className={style.formResponseRightContainer}>
          <div className={style.formResponseContentContainer}>
            <div dangerouslySetInnerHTML={{__html: props.modalData.html}}></div>
          </div>
          <a className={style.closeLink} onClick={props.hideModal} tabIndex="0">CLOSE</a>
        </div>
      </div>
    );
  }
}