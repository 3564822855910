import { h, Component } from 'preact';

import style from '../../style/hero.sass';

import HandlebarsHandler from '../util/handlebarsHandler.js';

export default class Main extends Component {
  render(props, state) {
    if(!this.props.mergedLandingPageData){
      return null;
    }
    const containerStyles = [style.container];
    const titleStyles = [style.title];
    const subtitleStyles = [style.subtitle];
    const blurbStyles = [style.blurb];
    if(props.brand === 'ruud'){
      containerStyles.push(style.containerRuud);
      titleStyles.push(style.titleRuud);
      subtitleStyles.push(style.subtitleRuud);
      blurbStyles.push(style.blurbRuud);
    }
    const handlebarsHandler = new HandlebarsHandler();
    const titleHtml = handlebarsHandler.renderToHtml(
      'mainContentTitleTemplate',
      props.mergedLandingPageData.mainContentTitleTemplate,
      props.mergedLandingPageData
    );
    const subtitleHtml = handlebarsHandler.renderToHtml(
      'mainContentSubtitleTemplate',
      props.mergedLandingPageData.mainContentSubtitleTemplate,
      props.mergedLandingPageData
    );
    const blurbHtml = handlebarsHandler.renderToHtml(
      'mainContentBlurb',
      props.mergedLandingPageData.mainContentBlurb,
      props.mergedLandingPageData
    );
    return (
      <div className={containerStyles.join(' ')}>
        <div className={style.brandInfoContainer}>
          <p className={titleStyles.join(' ')}>
            <span dangerouslySetInnerHTML={{__html: titleHtml}}></span>
            &nbsp;&nbsp;
            <span dangerouslySetInnerHTML={{__html: subtitleHtml}}></span>
          </p>
          <p className={blurbStyles.join(' ')} dangerouslySetInnerHTML={{__html: blurbHtml}}></p>
        </div>
      </div>
    );
  }
}