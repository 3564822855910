'use strict';

import Handlebars from 'handlebars';

export default class HandlebarsHandler {
  constructor(options){
    this.templates = {};
    this.registerHelpers();
  }
  registerHelpers(){
    Handlebars.registerHelper('titleCase', function(str, options) {
      var newstr = (str+'').split(" ");
      for(var i=0;i<newstr.length;i++){
        var copy = newstr[i].substring(1).toLowerCase();
        newstr[i] = newstr[i][0].toUpperCase() + copy;
      }
       newstr = newstr.join(" ");
       return newstr;
    });

    Handlebars.registerHelper('upperCase', function(str, options) {
       return (str+'').toUpperCase();
    });

    Handlebars.registerHelper('sanitizePhoneNum', function(str, options) {
       return (str+'').replace( /[^\d]/g, '' );
    });

    Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
      switch (operator) {
        case '==':
          return (v1 == v2) ? options.fn(this) : options.inverse(this);
        case '===':
          return (v1 === v2) ? options.fn(this) : options.inverse(this);
        case '<':
          return (v1 < v2) ? options.fn(this) : options.inverse(this);
        case '<=':
          return (v1 <= v2) ? options.fn(this) : options.inverse(this);
        case '>':
          return (v1 > v2) ? options.fn(this) : options.inverse(this);
        case '>=':
          return (v1 >= v2) ? options.fn(this) : options.inverse(this);
        case '&&':
          return (v1 && v2) ? options.fn(this) : options.inverse(this);
        case '||':
          return (v1 || v2) ? options.fn(this) : options.inverse(this);
        default:
          return options.inverse(this);
      }
    });
  }
  getTemplate(name, source){
    if(!this.templates[name]){
      this.templates[name] = Handlebars.compile(source);
    }
    return this.templates[name];
  }
  renderToHtml(name, source, data){
    return this.getTemplate(name, source)(data);
  }
};