import { h, Component } from 'preact';

import style from '../../style/header.sass';

import HeaderLogo from './headerLogo.js';
import HeaderTagline from './headerTagline.js';
import HeaderImage from './headerImage.js';

export default class Header extends Component {
  render(props, state) {
    return (
      <div id="header" className={style.container}>
        <HeaderLogo {...props} />
      </div>
    );
  }
}
