import { h, Component } from 'preact';

import style from '../../style/product.sass';
import HandlebarsHandler from '../util/handlebarsHandler.js';

const canadianStates = ['Ontario'];

export default class Product extends Component {
  render(props, state) {
    if(!props.mergedLandingPageData){
      return null;
    }
    let bannerSrc = 'assets/images/rheem/rheem-product-banner.svg';
    if(props.mergedLandingPageData.brand === 'ruud'){
      bannerSrc = 'assets/images/ruud/ruud-product-banner.svg';
    }
    let showBanner = (props.campaignType === 'brand');
    if(canadianStates.indexOf(props.mergedLandingPageData.state) > -1 ){
      showBanner = false;
    }


    showBanner = false;

    const productImageName = props.mergedLandingPageData.product_image_url.substr(
      props.mergedLandingPageData.product_image_url.lastIndexOf('/') + 1,
      props.mergedLandingPageData.product_image_url.lastIndexOf('.') -
      props.mergedLandingPageData.product_image_url.lastIndexOf('/') -1
    );
    const bannerClassName = 'banner-'+productImageName;
    const handlebarsHandler = new HandlebarsHandler();
    let productNameHtml = '';
    if(props.mergedLandingPageData.productName){
      productNameHtml = handlebarsHandler.renderToHtml(
        'productNameTemplate',
        props.mergedLandingPageData.productName,
        props.mergedLandingPageData
      );
    }
    const banner = showBanner?(<img className={style[bannerClassName]} src={bannerSrc} />):null;
    const imageStyle = (props.campaignType === 'repair')?style.repairImage:style.image;
    return (
      <div className={style.imageContainer}>
        <div className={style.imageFrame}>
          <img className={imageStyle} src="assets/images/product.png" />
          {banner}
        </div>
      </div>
    );
  }
}