import { h, Component } from 'preact';

import FontAwesomeIcon from './fontAwesomeIcon.js';
import { faSpinner, faSearch } from '@fortawesome/free-solid-svg-icons';

import ContractorPhoneNumber from './contractorPhoneNumber.js';

import style from '../../style/contractorList.sass';

const getPrimaryAddress = (addresses)=>{
  if(Array.isArray(addresses)){
    return addresses.find(address=>{
      return (address.isPrimary === true);
    });
  }
  return {};
};

const getMilesFromMeters = (meters) => {
  return Math.round(meters / 1600);
};

//<p className={[style.contractorListItemNameAddressBottom, brandFontStyle].join(' ')}>{getMilesFromMeters(props.contractor.distance)} miles</p>

const ContractorListItem = props => {
  const primaryAddress = getPrimaryAddress(props.contractor.addresses);

  return (
    <li className={style.contractorListItem}>
      <div className={style.contractorListItemInnerContainer}>
        <div className={style.contractorListItemLeftContainer}>
          <h4 className={style.contractorListItemName}>{props.contractor.name.toUpperCase()}</h4>
          <p className={style.contractorListItemNameAddressTop}>{primaryAddress.street}</p>
          <p className={style.contractorListItemNameAddressMiddle}>{primaryAddress.city}, {primaryAddress.state} {primaryAddress.zip}</p>
          <ContractorPhoneNumber {...props} contractor={props.contractor} />
        </div>
        <div className={style.contractorListItemRightContainer}>
          <div className={style.contractorListItemHours}>
            <label>HOURS</label>
            <div className={style.contractorListItemHoursText}>
              {props.contractor.externalData.hours}
            </div>
          </div>
          <button onClick={(e)=>{
            e.preventDefault();
            props.showForm(props.contractor);
          }} className={style.contractorFormLink}>
            SCHEDULE NOW
          </button>
        </div>
      </div>
    </li>
  );
};

//<FontAwesomeIcon icon={faEdit} /> &nbsp;

export default class ContractorList extends Component {
  constructor(props) {
    super(props);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.state = {
      distance: '40',
      zip: '',
      error: ''
    };
  }
  handleSearchInputChange(e){
    this.setState({[e.target.name]: e.target.value});
  }
  onSearchClick(e){
    e.preventDefault();
    if(!this.state.zip){
      this.setState({error: 'zip'});
      return;
    }
    if(!this.state.distance){
      this.setState({error: 'distance'});
      return;
    }
    this.setState({error: ''});
    this.props.setSearchLocation({...this.state});
  }
  render(props, state) {
    const contractors = Array.isArray(props.contractors)?props.contractors:[];
    const spinner = this.props.formSendPending?<FontAwesomeIcon icon={faSpinner} pulse />:null;
    const searchBarStyle = [style.listSearchBar];
    if(props.brand === 'ruud'){
      searchBarStyle.push(style.listSearchBarRuud);
    }
    return (
      <div id="contractor-list" className={style.container}>
        <div id="contractor-list-preamble" className={style.contractorListPreamble}>
          <div className={style.contractorListPreambleTitle}>
            FIND YOUR LOCAL PRO PARTNER
          </div>
          <div className={style.contractorListPreambleText}>
            If you’re looking for an efficient, dependable heating or cooling service, look no further than a Ruud Pro Partner.
            Even though our local contractors specialize in Ruud products, they can service any and all major brands.
            Use the table below to find a Pro Partner in your area!
          </div>
        </div>
        <div id="contractor-list-search-bar" className={searchBarStyle.join(' ')}>
          <div className={style.searchBarField}>
            <input className={(this.state.error == 'zip')?'error':null} value={this.state.zip} placeholder="Enter your zip code" name="zip" onChange={this.handleSearchInputChange} />
            <span className={style.searchIcon}><FontAwesomeIcon icon={faSearch} /></span>
          </div>
          <div className={style.searchBarField}>
            <select className={(this.state.error == 'distance')?'error':null} name="distance" value={this.state.distance} onChange={this.handleSearchInputChange}>
              <option value="16">Within 10 Miles</option>
              <option value="40">Within 25 Miles</option>
              <option value="80">Within 50 Miles</option>
            </select>
          </div>
          <div className={style.searchBarField}>
            <button type="button" onClick={this.onSearchClick} disabled={this.props.formSendPending}>
              SEARCH
              {spinner}
            </button>
          </div>
        </div>
        <div className={style.listCount}>
          <span className={style.listCountProPartners}>{contractors.length} Pro Partners</span>
          <span> near your location</span>
        </div>
        <ul className={style.contractorList}>
          {contractors.map(contractor=>{
            return (<ContractorListItem {...props} contractor={contractor} />);
          })}
        </ul>
      </div>
    );
  }
}
