import { h, Component } from 'preact';

import style from '../../style/header.sass';

export default class HeaderImage extends Component {
  render(props) {
    if(!props.brand){
      return null;
    }
    return (
      <img className={style.logo} src="assets/images/ruud/ruud-pro-partner-logo.png" />
    );
  }
}