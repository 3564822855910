import { h, Component } from 'preact';

//import FontAwesomeIcon from './fontAwesomeIcon.js';
//import { faEdit } from '@fortawesome/pro-solid-svg-icons';

import ContractorPhoneMumber from './contractorPhoneNumber.js';
//import DesktopCallToAction from './desktopCallToAction.js';

import style from '../../style/contractor.sass';
import ctaStyle from '../../style/desktopCallToAction.sass';

const StaticDesktopCallToAction = props => {
  return (
    <div className={ctaStyle.container}>
      <div className={ctaStyle.offerStatic} onClick={props.onContractorListLinkClick}>
        SCHEDULE YOUR
        <span className={ctaStyle.offerFree}>FREE</span>
        IN-HOME ESTIMATE TODAY!
      </div>
    </div>
  );
};

const ContractorSpotlight = props => {
  const contractorStyle = (props.brand === 'ruud')?style.contractorRuud:style.contractor;
  const contractorHeaderStyle = (props.brand === 'ruud')?style.contractorHeaderRuud:style.contractorHeader;
  const contractorListLinkStyle = (props.brand === 'ruud')?style.contractorListLinkRuud:style.contractorListLink;
  const contractorNameStyle = (props.brand === 'ruud')?style.contractorNameRuud:style.contractorName;
  const contractorFormLinkContainerStyle = (props.brand === 'ruud')?style.contractorFormLinkContainerRuud:style.contractorFormLinkContainer;
  const contractorFormLinkStyle = (props.brand === 'ruud')?style.contractorFormLinkRuud:style.contractorFormLink;

  return (
    <div className={contractorStyle}>
      <div className={contractorHeaderStyle}>YOUR LOCAL PRO PARTNER</div>
      <div className={contractorNameStyle}>{props.contractor.name.toUpperCase()}</div>
      <ContractorPhoneMumber {...props} contractor={props.contractor} />
      <div className={contractorFormLinkContainerStyle}>
        <button onClick={(e)=>{
          e.preventDefault();
          props.showForm(props.contractor);
        }} className={contractorFormLinkStyle}>
          SCHEDULE NOW
        </button>
      </div>
      <div className={contractorListLinkStyle}>
        <a href="" onClick={props.onContractorListLinkClick}>See below for full list of <br />Pro Partners in your area</a>
      </div>
    </div>
  );
};

//<FontAwesomeIcon icon={faEdit} /> &nbsp;

export default class Contractor extends Component {
  constructor(props) {
    super(props);
  }
  render(props, state) {
    const contractor = Array.isArray(props.contractors)?props.contractors[0]:null;
    if(!contractor){
      return null;
    }
    let backgroundImageUrl = 'assets/images/rheem/rheem_contractor_background.jpg';
    if(props.brand === 'ruud'){
      backgroundImageUrl = 'assets/images/ruud/ruud_contractor_background.jpg';
    }
    const containerStyles = [style.container];
    if(props.campaignType === 'repair'){
      if(props.brand === 'ruud'){
        containerStyles.push(style.ruudRepair);
      }else{
        containerStyles.push(style.repair);
      }
    }
    const borderStyle = (props.brand === 'ruud')?style.borderRuud:style.border;
    const innerStyle = (props.brand === 'ruud')?style.innerRuud:style.inner;
    const innerInnerStyle = (props.brand === 'ruud')?style.innerInnerRuud:style.innerInner;
    const innerImageContainerStyle = (props.brand === 'ruud')?style.innerImageContainerRuud:style.innerImageContainer;
    const innerImageStyle = (props.brand === 'ruud')?style.innerImageRuud:style.innerImage;
    const bubbleBorderStyle = (props.brand === 'ruud')?style.bubbleBorderRuud:style.bubbleBorder;
    const bubbleInnerStyle = (props.brand === 'ruud')?style.bubbleInnerRuud:style.bubbleInner;

    return (
      <div id="contractor" className={containerStyles.join(' ')}>
        <div className={style.outline}>
          <div className={borderStyle}>
            <div className={innerStyle}>
              <div className={innerInnerStyle}>
                <div className={innerImageContainerStyle}>
                  <img className={innerImageStyle} src={backgroundImageUrl} />
                </div>
              </div>
            </div>
          </div>
          <div className={bubbleBorderStyle}></div>
          <div className={bubbleInnerStyle}>
            <StaticDesktopCallToAction {...props} />
          </div>
          <ContractorSpotlight  {...props} contractor={contractor} />
        </div>
      </div>
    );
  }
}
//<DesktopCallToAction {...props} contractor={contractor} />