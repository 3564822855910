import { h, Component } from 'preact';

import style from '../../style/product.sass';
//import HandlebarsHandler from '../util/handlebarsHandler.js';


export default class Product extends Component {
  render(props) {
    if(!props.mergedLandingPageData){
      return null;
    }
    return (
      <div className={style.infoContainer}>
        <div className={style.infoPanel}>
          <div className={style.offerIntro}>CONTACT A LOCAL PRO PARTNER TODAY FOR</div>
          <div className={style.offerHeadline}>{this.props.offer.headline}</div>
          <div className={style.offerText}>{this.props.offer.text}<span className={style.disclaimerAsterisk}>*</span></div>
        </div>
      </div>
    );
  }
}