import { h, Component } from 'preact';

import scrollToElement from 'scroll-to-element';

import FontAwesomeIcon from './fontAwesomeIcon.js';
import { faSpinner, faSearch } from '@fortawesome/free-solid-svg-icons';
import style from '../../style/form.sass';

export default class LocationSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: null,
    };

    this.locationInput = null;
    this.locationAutocomplete = null;
    this.onPlaceChanged = this.onPlaceChanged.bind(this);
  }
  componentDidMount(){
    this.bindLocationInput();
    if(this.props.doShowModal){
      scrollToElement('body',{
        align: 'top'
      });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot){
    this.bindLocationInput();
    if(this.props.doShowModal && this.props.doShowModal != prevProps.doShowModal){
      scrollToElement(this.base,{
        align: 'top'
      });
    }
  }
  bindLocationInput(){
    if(this.props.googleMapsApiKeyIsValid && !this.locationAutocomplete){
      this.locationAutocomplete = new window.google.maps.places.Autocomplete(
        this.locationInput, {
          types: ['geocode'],
        }
      );
      this.locationAutocomplete.addListener('place_changed', this.onPlaceChanged);
    }else{
      this.locationInput.onChange = this.handleInputChange;
    }
  }
  onPlaceChanged(){
    const location = this.locationAutocomplete.getPlace();
    if(location){
      const locationData = {
        longitude: location.geometry.location.lng(),
        latitude: location.geometry.location.lat()
      };
      this.setState({formSendPending: true, location: locationData}, ()=>{
        this.props.modalData.setSearchLocation(locationData).then(()=>{
          this.props.hideModal();
        });
      });
    }
  }
  render(props, state){
    const spinner = this.props.formSendPending?<FontAwesomeIcon icon={faSpinner} pulse />:null;
    return (
      <form className={style.formLocationSearch}  onSubmit={this.handleSubmit}>
        <div className={style.formLocationSearchHeader}>
            WE COULD NOT DETERMINE YOUR LOCATION.
            PLEASE ENTER AN ADDRESS OR POSTAL CODE TO CONTINUE.
        </div>
        <div className={style.formLocationSearchFields}>
          <div className={style.formLocationSearchInputContainer}>
            <input className={style.formLocationSearchInput}
              type="text"
              placeholder="ZIP/ADDRESS"
              ref={(input) => { this.locationInput = input; }}
            />
            <span className={style.formLocationSearchIcon}><FontAwesomeIcon icon={faSearch} /></span>
          </div>
          <div className={style.formLocationGoContainer}>
            {spinner}
          </div>
        </div>
      </form>
    );
  }
}

/*
<div className={style.formLocationGoContainer}>
  <button className={style.formLocationGoButton} disabled={this.props.formSendPending} type="submit">
    GO
    {spinner}
  </button>
</div>
*/
