import { h, Component } from 'preact';

import style from '../../style/product.sass';
import HandlebarsHandler from '../util/handlebarsHandler.js';

import ProductImage from './productImage.js';
import ProductInfo from './productInfo.js';

export default class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayInfoFirst: false,
    };

    this.setDoDisplayInfoFirst = this.setDoDisplayInfoFirst.bind(this);
  }
  componentDidMount(){
    this.isMobileScreenQuery = window.matchMedia('screen and (max-width: 1023px)');
    this.isMobileScreenQuery.addListener(this.setDoDisplayInfoFirst);
    this.setDoDisplayInfoFirst(this.isMobileScreenQuery);
  }
  componentDidUpdate(){
    this.setDoDisplayInfoFirst(this.isMobileScreenQuery);
  }
  setDoDisplayInfoFirst(mediaQuery){
    const isMobileScreen = mediaQuery.matches;
    let displayInfoFirst = false;
    if(isMobileScreen && this.props.campaignType === 'repair'){
      displayInfoFirst = true;
    }
    if(this.state.displayInfoFirst !== displayInfoFirst){
      this.setState({displayInfoFirst});
    }
  }
  render(props) {
    if(!props.mergedLandingPageData){
      return null;
    }

    if(this.state.displayInfoFirst){
      return (
        <div id="product" className={style.container}>
          <ProductInfo {...props} />
          <ProductImage {...props} />
        </div>
      );
    }
    return (
      <div id="product" className={style.container}>
        <ProductImage {...props} />
        <ProductInfo {...props} />
      </div>
    );
  }
}