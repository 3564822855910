import { h, Component } from 'preact';

import FontAwesomeIcon from './fontAwesomeIcon.js';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

import ContentHandler from '../util/contentHandler.js';
import style from '../../style/contractor.sass';

export default class ContractorPhoneNumber extends Component {
//<i className={style.contractorIcon}><FontAwesomeIcon icon={faPhone} size="32px" /></i>
  render(props, state) {
    const contractorId = props.contractor?props.contractor.id:null;
    const contentHandler = new ContentHandler();
    const trackingNumber = contentHandler.getTrackingPhoneNumber(props, contractorId);
    if(!trackingNumber){
      return null;
    }
    return (
      <div className={style.contractorPhoneNumberContainer}>
        <a className={style.contractorPhoneNumber} onClick={props.sendClickToCallData} href={'tel:'+trackingNumber}>
          <i className={style.contractorPhoneIcon}><FontAwesomeIcon className="fa-flip-horizontal" icon={faPhone} size="16px" /></i>
          <span className={style.contractorPhoneNumberText}>{contentHandler.formatPhoneNumber(trackingNumber)}</span>
        </a>
      </div>
    );
  }
}
