import { h, Component } from 'preact';

import Form from './form.js';
import LocationSearch from './locationSearch.js';
import style from '../../style/modal.sass';

export default class Modal extends Component {
  render(props) {
    const ModalComponentType = props.modalComponentType;
    const modalStyles = [style.modal];
    const modalWindowStyles = [style.modalWindow];
    if(ModalComponentType === Form){
      modalWindowStyles.push(style.modalWindowForm);
    }else if(ModalComponentType === LocationSearch){
      modalStyles.push(style.modalLocationSearch);
      modalWindowStyles.push(style.modalWindowLocationSearch);
    }else{
      modalWindowStyles.push(style.modalWindowDefault);
    }
    return (
      <div id="form-modal" className={modalStyles.join(' ')} style={{
        visibility: props.doShowModal?'visible':'hidden',
        height: props.modalOverlayHeight+'px'
      }}>
        <div className={modalWindowStyles.join(' ')}>
          <div className={style.component}>
            <ModalComponentType {...props} />
          </div>
        </div>
      </div>
    );
  }
}
