import { h, Component } from 'preact';

import style from '../../style/divider.sass';

export default class Divider extends Component {
  render(props, state) {
    return (
      <div id="air-waves-divider" className={style.container}>
        <div className={style.divider} />
      </div>
    );
  }
}
